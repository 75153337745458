import React, { useState, useEffect } from "react";
import "./App.css";
import Marketing from "./Marketing.json";

import ImageAction from "./images/Action.svg";
import ImageContent1 from "./images/Content1.svg";
import ImageContent2 from "./images/Content2.svg";
import ImageContent3 from "./images/Content3.svg";

import { Row, Col } from "reactstrap";

function App() {
  const [dev, setDev] = useState(false)

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "prod") {
      setDev(true)
    }
  }, []);

  return (
    <div className="page">
      {dev ? 
        <div onClick={() => setDev(false)} style={{textAlign: 'center', color: 'white', backgroundColor: 'darkred', position: 'fixed', top: 0, margin: '10px', zIndex: '9999', padding: '5px', borderRadius: '8px', width: '300px'}}>
          Currently in Dev Mode
        </div>
        : 
        ''
      }

      <div className="my-container"style={{}}>
        {/* ======== DESKTOP HERO ========  */}
        <div id="panel-benefits" style={{ padding: "10px", marginTop: '50px'}}>
          <Row style={{ marginBottom: "180px" }}>
            <Col style={{ position: "relative" }}>
              <div
                style={{
                  margin: "0",
                  position: "absolute",
                  top: "60%",
                  msTransform: "translateY(-50%)",
                  transform: "translateY(-50%)",
                }}
              >
                <center>
                  <div style={{width: '100%', paddingRight: '80px', marginTop: '32px', marginBottom: '40px'}}>
                    <a
                      href="/"
                      style={{
                        marginLeft: 0,
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#009CFE",
                        paddingLeft: "0",
                        position: "relative",
                        
                      }}
                    >
                      {Marketing.name}
                      <span style={{}}>{Marketing.nameAddon}</span>
                      <span style={{color: 'white', fontSize: '14px', fontWeight: '600'}}>.com</span>
                    </a>
                  </div>
                  <h1
                    style={{
                      fontWeight: "500",
                      lineHeight: "60px",
                      marginBottom: "20px",
                      fontSize: "54px",
                      paddingRight: "80px",
                    }}
                  >
                    {Marketing.page.action.header}
                  </h1>
                  <h3
                    style={{
                      lineHeight: "40px",
                      paddingRight: "80px",
                      fontSize: "24px",
                      color: '#ededed'
                    }}
                  >
                    {Marketing.page.action.subheader}
                  </h3>
                  <div style={{width: '100%', paddingRight: '80px', marginTop: '60px'}}>
                    <a className="hero-button" href={Marketing.links.discordURL} style={{fontSize: '18px'}}>
                      Join the Community Discord
                    </a>
                  </div>

                </center>
              </div>
            </Col>
            <Col>
              <div style={{position: 'relative'}}>
                <img
                  alt="Top-down view of houses with trees around them."
                  style={{
                    position: 'relative',
                    marginTop: "70px",
                    width: "90%",
                    borderRadius: "16px",
                    zIndex: '333'
                  }}
                  src={ImageAction}
                />
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{position: 'absolute', zIndex: '0', left: 0, top: 0}}>
                  <path fill="#27282B" d="M35.6,-62.7C48.9,-54,64.2,-50,69.3,-40.3C74.3,-30.6,69,-15.3,67.1,-1.1C65.3,13.2,66.9,26.4,64.2,40.1C61.6,53.9,54.7,68.3,43.3,72.5C31.8,76.6,15.9,70.5,1.1,68.6C-13.7,66.7,-27.5,69,-36.9,63.7C-46.3,58.4,-51.3,45.5,-57.9,33.6C-64.5,21.7,-72.7,10.8,-77.1,-2.5C-81.5,-15.9,-82.1,-31.8,-73.3,-40C-64.5,-48.2,-46.5,-48.7,-32.7,-57.1C-18.9,-65.6,-9.5,-82,0.8,-83.5C11.2,-85,22.3,-71.5,35.6,-62.7Z" transform="translate(100 100)" />
                </svg>
              </div>

            </Col>
          </Row>
        </div>

        {/* ======== MOBILE HERO ========  */}
        <div className="mobile-benefits" style={{ padding: "10px", marginTop: '0px', paddingTop: '0px' }}>
          <Row style={{ marginBottom: "80px" }}>
            <Col xs="auto" style={{ position: "relative" }}>
              <center>
              <div style={{width: '100%', marginTop: '0px', marginBottom: '00px'}}>
                    <a
                      href="/"
                      style={{
                        marginLeft: 0,
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#009CFE",
                        position: "relative",
                        
                      }}
                    >
                      {Marketing.name}
                      <span style={{}}>{Marketing.nameAddon}</span>
                      <span style={{color: 'white', fontSize: '14px', fontWeight: '600'}}>.com</span>
                    </a>
                  </div>
                  <center>
                <img
                  alt="Top-down view of houses with trees around them."
                  style={{
                    width: "100%",
                    borderRadius: "16px",
                    padding: "0px",
                  }}
                  src={ImageAction}
                />
              </center>
                <h1
                  style={{
                    fontWeight: "500",
                    lineHeight: "60px",
                    marginBottom: "20px",
                    fontSize: "40px",
                    paddingRight: "10px",
                    marginTop: '40px'
                  }}
                >
                  {Marketing.page.action.header}
                </h1>
                <h3
                  style={{
                    lineHeight: "40px",
                    paddingRight: "10px",
                    fontSize: "24px",
                  }}
                >
                  {Marketing.page.action.subheader}
                </h3>
                <div style={{width: '100%', marginTop: '60px'}}>
                <a
              className="button-link"
              href={Marketing.links.discordURL}
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                padding: "30px 50px",
                marginTop: "40px",
                position: "relative",
                fontSize: "22px",
                fontWeight: "500",
                backgroundColor: "#009CFE",
              }}
            >
              Join the Discord
            </a>
                </div>
              </center>
            </Col>

          </Row>
        </div>
      </div>

      {/* <div style={{borderBottom: '1px solid #61656b'}} /> */}

      <div className="my-container" style={{backgroundColor: '#22252b', boxShadow: '0px -4px 16px rgba(0,0,0,.5)'}}>
        {/* ======== DESKTOP CONTENT ========  */}
        <div id="panel-benefits" style={{ marginTop: "100px" }}>
          <Row style={{ marginBottom: "200px" }}>
            <Col>
              <div style={{position: 'relative'}}>
                <img
                  src={ImageContent1}
                  style={{ width: "80%", borderRadius: "16px", position: 'relative', zIndex: '1' }}
                  alt="a guy with a phone in hand"
                />
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{position: 'absolute', zIndex: '0', left: '-50', top: '-150'}}>
                  <path fill="#32353D" d="M43.9,-63.3C58.6,-58.9,73.3,-49.5,79,-36.3C84.8,-23,81.6,-5.9,75.5,8.3C69.5,22.4,60.6,33.6,50.4,41.5C40.2,49.4,28.7,54,17.5,55.2C6.3,56.5,-4.5,54.4,-19.7,55.4C-34.9,56.3,-54.5,60.2,-64.5,53.3C-74.5,46.4,-75,28.6,-75.6,12C-76.1,-4.6,-76.8,-20,-70.8,-32C-64.7,-43.9,-52,-52.5,-39.1,-57.7C-26.2,-63,-13.1,-64.9,0.8,-66.1C14.6,-67.2,29.2,-67.7,43.9,-63.3Z" transform="translate(100 100)" />
                </svg>
              </div>
            </Col>
            <Col style={{ position: "relative" }}>
              <div
                style={{
                  margin: "0",
                  position: "absolute",
                  top: "50%",
                  msTransform: "translateY(-50%)",
                  transform: "translateY(-50%)",
                }}
              >
                <div style={{position: 'relative'}}>
                  <h1
                    style={{
                      fontWeight: "500",
                      lineHeight: "40px",
                      marginBottom: "20px",
                      fontSize: "36px",
                      paddingLeft: "80px",
                      textDecoration: "underline",
                      textDecorationColor: "#009CFE",
                      textDecorationThickness: "4px",
                    }}
                  >
                    {Marketing.page.content[0].header}
                  </h1>
                </div>
                <h3
                  style={{
                    lineHeight: "40px",
                    paddingLeft: "80px",
                    fontSize: "20px",
                    color: '#ededed'
                  }}
                >
                  {Marketing.page.content[0].paragraph}
                </h3>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: "200px" }}>
            <Col style={{ position: "relative" }}>
              <div
                style={{
                  margin: "0",
                  position: "absolute",
                  top: "50%",
                  msTransform: "translateY(-50%)",
                  transform: "translateY(-50%)",
                }}
              >
                <h1
                  style={{
                    fontWeight: "500",
                    lineHeight: "40px",
                    marginBottom: "20px",
                    fontSize: "36px",
                    paddingRight: "80px",
                    textDecoration: "underline",
                    textDecorationColor: "#009CFE",
                    textDecorationThickness: "4px",
                  }}
                >
                  {Marketing.page.content[1].header}
                </h1>
                <h3
                  style={{
                    lineHeight: "40px",
                    paddingRight: "80px",
                    fontSize: "20px",
                    color: '#ededed'
                  }}
                >
                  {Marketing.page.content[1].paragraph}
                </h3>
              </div>
            </Col>
            <Col>
              <div style={{position: 'relative'}}>
                <img
                  src={ImageContent2}
                  style={{ width: "60%", borderRadius: "16px", float: 'right', position: 'relative', zIndex: '1', marginRight: '100px' }}
                  alt="a map with directions"
                />
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{position: 'absolute', zIndex: '0', right: '0', top: '-150'}}>
                  <path fill="#32353D" d="M33.1,-50.3C46,-43.2,61.6,-39.3,72.1,-29.2C82.6,-19.1,87.8,-2.8,85.1,11.8C82.3,26.5,71.6,39.6,60,50.8C48.4,62,36,71.2,22.2,74.9C8.4,78.6,-6.8,76.9,-21,72.4C-35.2,67.9,-48.4,60.6,-54.9,49.6C-61.3,38.5,-61,23.6,-63.9,8.7C-66.7,-6.2,-72.7,-21,-69.3,-32.9C-65.8,-44.8,-53,-53.7,-39.9,-60.6C-26.8,-67.6,-13.4,-72.6,-1.6,-70C10.1,-67.5,20.2,-57.3,33.1,-50.3Z" transform="translate(100 100)" />
                </svg>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: "200px" }}>
            <Col>
              <div style={{position: 'relative'}}>
                <img
                  src={ImageContent3}
                  style={{ width: "85%", borderRadius: "16px", position: 'relative', zIndex: '1' }}
                  alt="a girl on the moon reading a book"
                />
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{position: 'absolute', zIndex: '0', left: '-30', top: '-100'}}>
                  <path fill="#32353D" d="M47.5,-76.3C60.3,-65.6,68.7,-50.4,76.5,-34.6C84.3,-18.7,91.6,-2.3,85.7,9.6C79.8,21.5,60.7,28.7,48.2,40.1C35.8,51.4,30,66.8,18.7,75.7C7.4,84.5,-9.4,86.8,-24.4,82.7C-39.5,78.5,-52.8,67.9,-58.3,54.4C-63.8,41,-61.6,24.8,-62.6,9.7C-63.7,-5.3,-68,-19.2,-65,-31.3C-61.9,-43.4,-51.6,-53.7,-39.5,-64.8C-27.5,-76,-13.7,-87.8,1.8,-90.6C17.3,-93.4,34.6,-87.1,47.5,-76.3Z" transform="translate(100 100)" />
                </svg>
              </div>
            </Col>
            <Col style={{ position: "relative" }}>
              <div
                style={{
                  margin: "0",
                  position: "absolute",
                  top: "50%",
                  msTransform: "translateY(-50%)",
                  transform: "translateY(-50%)",
                }}
              >
                <h1
                  style={{
                    fontWeight: "500",
                    lineHeight: "40px",
                    marginBottom: "20px",
                    fontSize: "36px",
                    paddingLeft: "80px",
                    textDecoration: "underline",
                    textDecorationColor: "#009CFE",
                    textDecorationThickness: "4px",
                  }}
                >
                  {Marketing.page.content[2].header}
                </h1>
                <h3
                  style={{
                    lineHeight: "40px",
                    paddingLeft: "80px",
                    fontSize: "20px",
                    color: '#ededed'
                  }}
                >
                  {Marketing.page.content[2].paragraph}
                </h3>
              </div>
            </Col>
          </Row>
        </div>

        {/* ======== MOBILE CONTENT ========  */}
        <center>
        <div className="mobile-benefits" style={{ padding: "10px" }}>
          <Row style={{ marginBottom: "60px", marginTop: '60px' }}>
            <Col xs="auto" style={{ position: "relative", width: '100%' }}>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "500",
                  lineHeight: "40px",
                  marginBottom: "20px",
                  fontSize: "36px",
                  textDecoration: "underline",
                  textDecorationColor: "#009CFE",
                  textDecorationThickness: "4px",
                }}
              >
                {Marketing.page.content[0].header}
              </h1>
              <img
                src={ImageContent1}
                style={{
                  width: "100%",
                  borderRadius: "16px",
                  marginBottom: "10px"
                }}
                alt="a guy with a phone in hand"
              />
            </Col>
            <Col xs="auto">
              <h3
                style={{
                  lineHeight: "40px",
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                {Marketing.page.content[0].paragraph}
              </h3>
            </Col>
          </Row>
          <Row style={{ marginBottom: "60px" }}>
            <Col xs="auto" style={{ position: "relative", width: '100%' }}>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "500",
                  lineHeight: "40px",
                  marginBottom: "20px",
                  fontSize: "36px",
                  textDecoration: "underline",
                  textDecorationColor: "#009CFE",
                  textDecorationThickness: "4px",
                }}
              >
                {Marketing.page.content[1].header}
              </h1>
              <img
                src={ImageContent2}
                style={{
                  width: "100%",
                  borderRadius: "16px",
                  marginBottom: "10px"
                }}
                alt="a map with directions"
              />
            </Col>
            <Col xs="auto">
              <h3
                style={{
                  lineHeight: "40px",
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                {Marketing.page.content[1].paragraph}
              </h3>
            </Col>
          </Row>
          <Row style={{ marginBottom: "60px" }}>
            <Col xs="auto" style={{ position: "relative", width: '100%' }}>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "500",
                  lineHeight: "40px",
                  marginBottom: "20px",
                  fontSize: "36px",
                  textDecoration: "underline",
                  textDecorationColor: "#009CFE",
                  textDecorationThickness: "4px",
                }}
              >
                {Marketing.page.content[2].header}
              </h1>
              <img
                src={ImageContent3}
                style={{
                  width: "100%",
                  borderRadius: "16px",
                  marginBottom: "10px"
                }}
                alt="a girl on the moon reading a book"
              />
            </Col>
            <Col xs="auto">
              <h3
                style={{
                  lineHeight: "40px",
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                {Marketing.page.content[2].paragraph}
              </h3>
            </Col>
          </Row>
        </div>
      </center>
      </div>
               
      {/* ======== FOOTER ========  */}
      <center>
        <div
          style={{
            width: "100%",
            backgroundColor: "#009CFE",
            height: "400px",
            position: "relative",
            margin: "auto",
            boxShadow: '0px -4px 16px rgba(0,0,0,.5)'
          }}
        >
          <div
            style={{
              margin: "0",
              position: "absolute",
              top: "50%",
              msTransform: "translate(-50%, -50%)",
              transform: "translate(-50%, -50%)",
              left: "50%",
              marginRight: "-50%",
              padding: "20px",
            }}
          >
            <h1 style={{ color: "white" }}>{Marketing.page.footer.header}</h1>
            <br />
            <br />
            <a
              className="button-link"
              href={Marketing.links.discordURL}
              target="_blank"
              rel="noreferrer"
              style={{
                color: "white",
                padding: "30px 50px",
                marginTop: "40px",
                position: "relative",
                fontSize: "22px",
                fontWeight: "500",
                backgroundColor: "#52bcff",
              }}
            >
              Join the Discord
            </a>
          </div>
        </div>
      </center>
    </div>
  );
}

export default App;
